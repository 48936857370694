import cx from 'classnames';

export interface IMessageProps {
    className?: string;
    message: string;
    description?: string;
    variant?: 'alert' | 'notice' | 'success' | 'info';
    onClose?: () => void;
}

const Message: React.FC<IMessageProps> = ({ className, message, description, variant = 'notice', onClose }) => (
    <div
        className={cx('alert', className, {
            'alert-danger': variant === 'alert',
            'alert-warning': variant === 'notice',
            'alert-success': variant === 'success',
            'alert-info': variant === 'info',
            'alert-dismissible': !!onClose
        })}
    >
        {onClose && <a className="btn-close" aria-label="close" onClick={onClose}></a>}
        <h4 className="alert-title">{message}</h4>
        {description && <div className="text-muted">{description}</div>}
    </div>
);

export default Message;
