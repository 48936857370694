import React, { createContext, forwardRef, useContext, useImperativeHandle, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

interface IProps {
    onClose?: () => void;
}

interface AvatarModalHandle {
    open: (url: string) => void;
    close: () => void;
}

const AvatarModal = forwardRef<AvatarModalHandle, IProps>(({ onClose }, ref) => {
    const [url, setUrl] = useState<string>();
    const [show, setShow] = useState(url != null);

    useImperativeHandle(ref, () => ({
        open: (url: string) => {
            setUrl(url);
            setShow(true);
        },
        close: () => {
            setShow(false);
        }
    }));

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            onExited={() => {
                setUrl(undefined);
                onClose?.();
            }}
            className="modal-blur"
            contentClassName="div"
        >
            <img src={url} onClick={close} />
        </Modal>
    );
});

const AvatarModalContext = createContext<AvatarModalHandle>({
    open: () => {},
    close: () => {}
});

export const AvatarModalProvider = ({ children }: { children?: React.ReactNode }) => {
    const modalRef = React.useRef<AvatarModalHandle>(null);

    const value = useMemo(
        () => ({
            open: (url: string) => modalRef.current?.open(url),
            close: () => modalRef.current?.close()
        }),
        []
    );

    return (
        <AvatarModalContext.Provider value={value}>
            {children}
            <AvatarModal ref={modalRef} />
        </AvatarModalContext.Provider>
    );
};

export const useAvatarModal = () => useContext(AvatarModalContext);

export default AvatarModal;
